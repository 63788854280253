import React from "react";

// Import components
import Home from '../components/Home.jsx';

// CSS framework
import 'fomantic-ui-css/semantic.min.css';

const IndexPage = () => {
  return (
      <Home></Home>
  )
}

export default IndexPage
